<template lang='pug'>
	div
		router-view
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { STORAGE_LAST_OPENED_ITEM } from '@/api/storage';
import { DOMAIN } from '@/api/index';
import { STATUS_REMOVED, STATUS_ACTIVE } from '@/models/model';

export default {
	name: 'ProjectItem',
	props: ['id'],
	metaInfo() { return {title: this.getTitle}},
	data: () => ({
		isAccountsLoaded: false
	}),
	computed: {
		...mapGetters({
			projectList: 'project/itemsShort',
			item: 'project/opened',
			stats: 'project/statistics',
			plans: 'plans/list',
			planEdited: 'plans/edited',
			accountList: 'finance/accounts',
			accountListActiveRemoved: 'finance/accountsActiveRemoved',
			activeCards: 'finance/activeCards',
			isPendingProject: 'project/isPending',
			isGettingProject: 'project/GETTING_PROJECT'
		}),
		getTitle() {
			if (this.item) {
				return this.item.title;
			}
		}
	},
	mounted() {
		this.$root.$on('bv::modal::show', () => {
			if (this.isMobile)
				document.documentElement.classList.add("overflow-hidden")
		})
		this.$root.$on('bv::modal::hide', () => {
			if (this.isMobile)
				document.documentElement.classList.remove("overflow-hidden");
		})
	},
	created() {
		if (this.$route.query && this.$route.query.authRequestId)
			this.$router.replace({'query': null});

		if (!this.hasOpenedProjectBefore()) {
			if (this.projectList.length == 0) {
				this.$router.push({name: 'add_project'})
			}

			return;
		}

		this.initProject();
	},
	methods: {
		...mapActions({
			canBeDeleted: 'project/canBeDeleted',
			activate: 'project/activate',
			getItem: 'project/id',
			getChannels: 'resources/channels',
			getGroups: 'resources/groups',
			edit: 'project/edit',
			attachAccount: 'project/attachAccount',
			getAccounts: 'finance/accounts',
			getActiveCards: 'finance/activeCards',
			getAccountsFinance : 'users/finance',
			getPlansNeuro: 'plans/plansNeuro',
			getQrPaymentPage: 'project/qrPaymentPage',
			refreshBot: 'bot/refresh',
			getRecoveryCommon: 'comebackers/getRecoveryCommon',
			getRecoveryPayment: 'comebackers/getRecoveryPayment',
			getRecoveryComebacker: 'comebackers/getRecoveryComebacker',
			getComebackersList: 'comebackers/comebackersList',
		}),
		setLastOpenedItem() {
			this.$cookies.set(STORAGE_LAST_OPENED_ITEM, this.id, '1M', null, DOMAIN, null, 'Lax');
			this.$store.commit('project/setLastOpenedProjectId', this.id);
		},
		async initProject() {
			if ( this.item && this.item.id != this.id ) {
				this.$store.commit('project/setOpened', null);
			}
			this.$store.commit('project/setStatistics', null);
			this.setLastOpenedItem();

			_.each(this.filters, f => {
				if ( f.plan ) f.plan.title = this.$t('plan.title');
			});

			await this.canBeDeleted({id: this.id}).then( async v => {
				await this.getItem({id: this.id});
				this.item.setCanBeDeleted(v);
			});
			_.each(this.filters, f => f['plan.project'] = {value: this.item.id});

			this.$store.commit('plans/list', this.item.plans);
			this.$store.commit('resources/channels', this.item.channels);
			this.$store.commit('resources/groups', this.item.groups);

			this.isReady = true;

			await this.getAccounts({status: [STATUS_ACTIVE, STATUS_REMOVED], itemsPerPage: 1000});
			await this.getAccountsFinance({status: [STATUS_ACTIVE, STATUS_REMOVED], itemsPerPage: 1000}).then( list => {
				_.each(this.accountList, a => {
					_.each(list, l => {
						if ( a.id == l.id ) {
							a.setFinance(l);
						}
					})
				})
			})

			await this.getQrPaymentPage({id: this.id}).then(v => {
				this.$store.commit('project/setQrPaymentPage', v);
			});

			let a = _.filter( this.accountList, la => {
				return _.filter(this.item.accounts, aa => aa.id == la.id).length > 0;
			});

			this.$store.commit('project/setAccounts', a);
			this.$store.commit('finance/setAccountsActiveRemoved', this.accountList)
			this.me.setAccounts(this.accountListActiveRemoved);
			this.isAccountsLoaded = true;
			this.getActiveCards();

			if (this.item.isNeuro()) {
				this.refreshNeuroBot();
				this.getPlansNeuro();
			} else {
				await this.getComebackersList({project: this.item.id});
				await this.getRecoveryCommon({id: this.item.id});
				await this.getRecoveryPayment({id: this.item.id});
				await this.getRecoveryComebacker({id: this.item.id});
			}
		},
		goToFinance() {
			this.$router.push({name: 'finance', query: {editProfile: true}});
		},
		hasOpenedProjectBefore() {
			return this.id && this.id != 'unknown';
		},
		setTab(tab) {
			if (tab || tab == 0) return this.tab = tab;
			if (this.$route.params && this.$route.params.tab) this.tab = this.$route.params.tab;
		},
		refreshNeuroBot() {
			if (this.item && this.item.isNeuro() && this.item.bot) {
				this.refreshBot({id: this.item.bot.id}).then(i => {
					this.getItem({id: this.item.id});
				}).catch(v => {
					this.getItem({id: this.item.id});
				})
			}
		}
	},
	watch: {
		id(nv, ov) {
			if (nv && ov && nv != ov) {
				this.initProject();
			}
		},
		'$route.path'() {
			this.refreshNeuroBot();
		}
	},
}
</script>
